import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import { FONTS, COLORS, BREAKPOINTS } from '../../../styles/constants';

export const StyledReactionWrap = styled.div<{ disableLinks?: boolean }>`
  position: relative;

  a,
  .card,
  .card-link {
    pointer-events: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
    user-select: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
  }
`;

export const StyledTitle = styled.p<{ isDark: boolean }>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: -webkit-box;
  font: 400 14px/140% ${FONTS.primary};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledDate = styled.p<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 12px/140% ${FONTS.secondary};
  margin: 0;
`;

export const StyledWrap = styled.div<{
  bgColor: string;
  height: number;
  gradientStart?: string | null;
  gradientEnd?: string | null;
}>`
  background: ${({ gradientStart, bgColor }) => gradientStart ?? bgColor};
  border-radius: 8px;
  height: ${({ height }) => height}px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  &::before {
    background: ${({ gradientEnd }) =>
      `linear-gradient(${COLORS.transparent} 5%, ${gradientEnd ?? COLORS.black[60]} 100%)`};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: ${({ gradientStart }) => (gradientStart ? '1' : '0')};
  }
`;

export const StyledInner = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

export const StyledHead = styled.div`
  padding: 12px;
  position: relative;
`;

export const StyledImagesList = styled.ul<{ isMultiple: boolean }>`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative;

  &.images-2 {
    img {
      &:first-child {
        bottom: 0;
        left: 12px;
        position: absolute;
      }
      &:nth-child(2) {
        bottom: 0;
        position: absolute;
        left: 51px;
      }
    }
  }

  &.images-3 {
    img {
      &:first-child {
        bottom: 0;
        left: 12px;
        position: absolute;
      }
      &:nth-child(2) {
        bottom: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-child(3) {
        bottom: 0;
        position: absolute;
        right: 12px;
      }
    }
  }

  &.images-4 {
    img {
      &:first-child {
        bottom: 0;
        left: 12px;
        position: absolute;
      }
      &:nth-child(2) {
        bottom: 0;
        position: absolute;
        left: 54px;
      }

      &:nth-child(3) {
        bottom: 0;
        position: absolute;
        left: 96px;
      }

      &:nth-child(4) {
        bottom: 0;
        left: 138px;
        position: absolute;
      }
    }
  }
`;

export const StyledImageItem = styled.img<{ size: number }>`
  border-radius: 4px;
  height: auto;
  max-height: ${({ size }) => `${size * 1.336}px`};
  max-width: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};
  object-fit: cover;
  overflow: hidden;
  position: relative;
  width: ${({ size }) => `${size}px`};

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    max-height: ${({ size }) => `${size * 1.514}px`};
  }
`;

export const StyledFooter = styled.div`
  align-items: center;
  display: flex;
  margin-top: 12px;
  height: 20px;

  li {
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const StyledTextWrap = styled.div<{ bgColor: string }>`
  border-radius: 8px;
  border: 1px solid rgba(24, 24, 0, 0.1);
  margin: 0 auto 17px;
  overflow: hidden;
`;

export const StyledTextsList = styled.ul<{ bgColor: string; size: number; isLarge?: boolean }>`
  background: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: column;
  font-size: ${({ isLarge }) => (isLarge ? '12px' : '9px')};
  height: ${({ size }) => `${size}px`};
  list-style: none;
  margin: 0;
  padding: 6.5%;
  width: ${({ size }) => `${size}px`};
`;

export const StyledTextItem = styled.li<{ opacity: number; color: string }>`
  color: ${({ color }) => color};
  line-height: 130%;
  opacity: ${({ opacity }) => opacity};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledListAuthors = styled.div<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 12px/140% ${FONTS.secondary};
  margin-left: 8px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const StyledLabel = styled.span`
  background-color: ${COLORS.white.off[100]};
  border-radius: 4px;
  bottom: 12px;
  color: ${COLORS.brown.dark[100]};
  font: 400 12px/140% ${FONTS.secondary};
  left: 12px;
  padding: 4px 8px;
  position: absolute;
  width: fit-content;
`;
