import React, { FC, useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Mousewheel, Swiper as SwipperClass } from 'swiper';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import CardDetails from 'components/CardDetails';
import useIsModalOpen from 'helpers/useIsModalOpen';
import { AutoScrollProvider } from 'components/DetailSlider/hooks';
import { ROUTE_CUSTOM_FEED_DETAIL, ROUTE_LIST_DETAIL } from 'routes';

import { NavigationContext } from 'providers/helpers/contexts';
import { INavigation } from 'providers/helpers/models';
import { ETutorialStatus, CardSchema } from 'constants/graphqlTypes';
import { useUserSettings } from 'graphQL/contextualIntro/hooks';
import { transformRoute } from 'helpers/routingHelper';
import { IDetailSliderListItem } from 'graphQL/cards/exploreCards/models';
import { useLazyGetDetailCard } from 'graphQL/card/helpers/hooks';

import { getIdByIndex } from './helpers/helpers';
import { useDetailSliderBg } from './helpers/hooks';

import StyledSlider from './styled';

interface IDetailSliderProps {
  card: CardSchema;
  items: IDetailSliderListItem[];
  handleRoute: (id: string) => void;
  handleLoadItems: () => void;
}

const DetailSlider: FC<IDetailSliderProps> = ({ card, items, handleRoute, handleLoadItems }) => {
  const { replace } = useHistory();
  const { state } = useLocation<{ feedId?: string; listId?: string }>();
  const { feedId, listId } = state ?? {};
  const [swiperInstance, setSwiperInstance] = useState<SwipperClass | null>(null);
  const [isAutoScrollStarted, setAutoScrollStarted] = useState<boolean>(false);

  const { pickId } = useParams<{ pickId: string }>();

  const [detailLink, setDetailLink] = useState('');

  const { id } = card;
  const { getLazyCard: getNextCard, lazyCard: nextCard, loading: nextLoading } = useLazyGetDetailCard();
  const { getLazyCard: getPrevCard, lazyCard: prevCard, loading: prevLoading } = useLazyGetDetailCard();
  const { getLazyCard: getSecondNextCard } = useLazyGetDetailCard();
  const { getLazyCard: getThirdNextCard } = useLazyGetDetailCard();
  const { gradientStart, gradientEnd, handleChange: changeBg } = useDetailSliderBg(card, prevCard, nextCard);
  const { loading, isCardReactionIntroCompleted, tutorialStatus } = useUserSettings();

  const isModalOpen = useIsModalOpen();

  const currentItemIndex = items.findIndex((item) => item.id === id);
  const prevItem = items[currentItemIndex - 1];
  const nextItem = items[currentItemIndex + 1];
  const secondNextItem = items[currentItemIndex + 2];
  const thirdNextItem = items[currentItemIndex + 3];

  const handleSwipeEnd = (index: number) => handleRoute(getIdByIndex(index, id, prevCard?.id, nextCard?.id));
  const isAllowSlide = isCardReactionIntroCompleted || tutorialStatus !== ETutorialStatus.Finished;

  const { setIsScrolled } = useContext<INavigation>(NavigationContext);

  useEffect(() => {
    if (isAllowSlide && !loading) {
      if (prevItem) {
        getPrevCard(prevItem);
      }
      if (nextItem) {
        getNextCard(nextItem);
      }
      if (secondNextItem) {
        getSecondNextCard(secondNextItem);
      }
      if (thirdNextItem) {
        getThirdNextCard(thirdNextItem);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, nextItem, prevItem, isCardReactionIntroCompleted, loading]);

  useEffect(() => {
    if (currentItemIndex > items.length - 3 || !items.some(({ id: itemId }) => id === itemId)) {
      handleLoadItems();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, items.length, currentItemIndex]);

  const [isAnimationStarted, setAnimationStarted] = useState(false);
  const hideReactionModal = () => {
    setIsScrolled?.(true);
    setAnimationStarted(() => {
      setTimeout(() => {
        setAnimationStarted(false);
      }, 50);

      return true;
    });
  };

  useEffect(() => {
    if (swiperInstance && isAutoScrollStarted && nextCard && !nextLoading) {
      swiperInstance?.slideNext(300);
    }
  }, [isAutoScrollStarted, currentItemIndex, swiperInstance, nextCard, items.length, nextLoading, id]);

  const isCurrentCard = pickId === id;

  useEffect(() => {
    if (!nextLoading && !nextItem && feedId && isCurrentCard && isAutoScrollStarted) {
      setDetailLink(transformRoute(ROUTE_CUSTOM_FEED_DETAIL, { feedId }));
    }
    if (!nextLoading && !nextItem && listId && isCurrentCard && isAutoScrollStarted) {
      setDetailLink(transformRoute(ROUTE_LIST_DETAIL, { listId }));
    }
  }, [nextLoading, listId, feedId, currentItemIndex, items.length, nextItem, isCurrentCard, isAutoScrollStarted]);

  useEffect(() => {
    if (detailLink) {
      replace(detailLink);
    }
  }, [detailLink, replace]);

  return (
    <AutoScrollProvider value={{ isAutoScrollStarted, setAutoScrollStarted }}>
      <StyledSlider key={id} gradientStart={gradientStart} gradientEnd={gradientEnd}>
        <Swiper
          onSwiper={setSwiperInstance}
          speed={400}
          followFinger
          initialSlide={1}
          allowSlidePrev={!!prevCard && !!prevItem && !isModalOpen}
          allowSlideNext={!!nextCard && !!nextItem && !isModalOpen}
          onTransitionEnd={({ realIndex }) => {
            handleSwipeEnd(realIndex);
            setIsScrolled?.(false);
            setAutoScrollStarted?.(false);
          }}
          onSlideChange={({ realIndex }) => {
            changeBg(realIndex);
            setAnimationStarted(false);
          }}
          direction="vertical"
          grabCursor
          mousewheel={{ sensitivity: 0.6, thresholdTime: 100 }}
          modules={[Mousewheel, Autoplay]}
          touchStartPreventDefault={false}
          onSliderMove={hideReactionModal}
          onTransitionStart={hideReactionModal}
          onAnimationStart={hideReactionModal}
        >
          <SwiperSlide>
            {prevCard && !prevLoading && (
              <CardDetails swiperInstance={swiperInstance} item={prevCard} isAnimationStarted={isAnimationStarted} />
            )}
          </SwiperSlide>
          <SwiperSlide>
            <CardDetails swiperInstance={swiperInstance} item={card} isAnimationStarted={isAnimationStarted} />
          </SwiperSlide>
          <SwiperSlide>
            {nextCard && !nextLoading && (
              <CardDetails swiperInstance={swiperInstance} item={nextCard} isAnimationStarted={isAnimationStarted} />
            )}
          </SwiperSlide>
        </Swiper>
      </StyledSlider>
    </AutoScrollProvider>
  );
};

export default DetailSlider;
